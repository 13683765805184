.search-input-wrap {
    max-width: 366px;
    width: 100%;
    position: relative;
  }
  
  .search-input {
    max-width: 366px;
    width: 100%;
    height: 35px;
    background-color: #171a1f;
    border-radius: 6px;
    color: #bdc1ca;
    border: none;
    font-size: 11pt;
    padding-left: 36px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 6px;
  }
  
  .search-icon {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  
.course-background {
  width: 100vw;
  min-height: 1055px;
  background-color: #171a1f;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 90px 30px 50px;
}

.course-section {
  max-width: 1180px;
  width: 100%;
  min-height: 1055px;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Lexend", Helvetica;
  color: #dee1e6;
}

.course-title {
  font-size: 24px;
  font-weight: bold;
  font-family: "Lexend", Helvetica;
}

.course-desc {
  margin-top: 16px;
  border-top: 3px solid #323743;
  padding-top: 36px;
  padding-bottom: 52px;
  display: flex;
  gap: 54px;
}

.course-preview {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 23px;
}

.course-image-wrap {
  max-width: 425px;
  width: 100%;
  max-height: 288px;
  overflow: hidden;
  min-width: 300px;
}

.course-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.course-index-text {
  font-weight: bold;
  font-size: 20px;
  font-family: "Lexend", Helvetica;
}

.course-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.enrolled-course-info {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.course-info-flex {
  display: flex;
  row-gap: 12px;
  column-gap: 36px;
  margin-top: 28px;
}

.course-info-price {
  margin-bottom: 20px;
}

.course-info-service-period {
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.course-duration,
.course-price {
  font-size: 20px;
  font-weight: 400;
}

.course-service-period {
  font-size: 14px;
}

.course-price {
  display: flex;
  gap: 19px;
}

.course-price del {
  color: #9095a1;
  font-size: 18px;
}

.course-detail,
.course-lecture-list,
.course-QnA {
  border-top: 1px solid #323743;
  padding-top: 39px;
  padding-bottom: 62px;
  display: flex;
  gap: 4px;
  align-items: start;
  font-family: '"Manrope", Helvetica';
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
}

.course-detail-desc {
  margin-top: 28px;
  white-space: pre-wrap;
}


@media screen and (max-width: 900px) {
  .course-desc {
    flex-direction: column;
  }
}

.enrolled-course-progress-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.enrolled-course-progress-wrap {
  display: flex;
  gap: 17px;
  align-items: center;
}

.enrolled-course-progress{
  -webkit-appearance: none;
  appearance: none;
}

.enrolled-course-progress::-webkit-progress-bar {
  max-width: 300px;
  width: 100%;
  height: 12px;
  background-color: #dee1e6;
  border-radius: 6px;
}

progress {
  max-width: 300px;
  width: 100%;
  height: 12px;
  border-radius: 6px;
  background-color: #dee1e6;
}

.enrolled-course-progress::-webkit-progress-value {
  background-color: #BD9A31;
  border-radius: 6px;
}

.enrolled-course-survey-wrap, .enrolled-course-certification-wrap {
  display: flex;
  gap: 60px;
  align-items: center;
}
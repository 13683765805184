.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.video-modal-content {
  position: relative;
  padding: 20px;
  display: flex;
  justify-content: center;
}

.video-modal-video {
  width: 90%;
  max-width: 90vw;
  height: auto;
}

.video-modal-close {
  position: absolute;
  font-size: 50px;
  cursor: pointer;
  top: -20px;
  right: 16px;
  color: white;
}

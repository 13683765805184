.post-editor-page-background {
  background-color: #171a1f;
  padding: 32px 107px 102px;
  min-height: calc(100vh - 286px);
}

.post-editor-page {
  color: #dee1e6;
  font-family: "Manrope", Helvetica;
  background-color: #1e2128;
  max-width: 800px;
  margin: auto;
  padding: 24px 20px;
  border-radius: 6px;
}

.post-editor-title-wrap {
  display: flex;
  justify-content: space-between;
}

.post-editor-title {
  font-size: 20px;
  font-weight: 700;
}

.post-editor-label {
  display: block;
  margin-top: 22px;
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 700;
}

.post-editor-title-input {
  background-color: #323743;
  max-width: 574px;
  width: 100%;
  height: 35px;
  border-radius: 4px;
  border: 0;
  color: #bdc1ca;
  font-size: 14px;
  padding: 7px 12px;
}

.post-editor-title-input::placeholder {
  color: #bdc1ca;
}

#post-editor-category {
  background-color: #323743;
  max-width: 574px;
  width: 100%;
  height: 35px;
  border-radius: 4px;
  border: 0;
  color: #bdc1ca;
  font-size: 14px;
  padding: 7px 9px;
}

#post-editor-textarea {
  margin-top: 54px;
  display: block;
  width: 100%;
  max-width: 955px;
  min-height: 306px;
  border-radius: 4px;
  border: 0;
  background-color: #323743;
  font-size: 14px;
  padding: 7px 12px;
  color: #bdc1ca;
  resize: none;
  line-height: 22px;
}

#post-editor-textarea::placeholder {
  color: #bdc1ca;
}

@media screen and (max-width: 800px){
  .post-editor-page-background {
    padding: 32px 10px 60px;
  }
}
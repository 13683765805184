.certificate-table-container {
  max-width: 1080px;
  width: 100%;
  margin-top: 28px;
}

.certificate-table-header {
  font-size: 14px;
  font-weight: 600;
  border-bottom: 0.5px solid #dee1e6;
  padding: 20px 22px;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 1fr;
}

.certificate-table-item {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 1fr;
  align-items: center;
}

.certificate-table-loading-item{
  padding: 20px;
}

.certificate-table-item {
  font-family: "Manrope", Helvetica;
  font-size: 14px;
  font-weight: 400;
  color: #dee1e6;
  height: 86px;
  padding: 16px 22px;
}

.certificate-table-image-wrap {
  width: 80px;
  min-width: 80px;
  height: 54px;
  border-radius: 4px;
  overflow: hidden;
}

.certificate-table-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.certificate-table-item-title-wrap {
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 0 16px 0 0;
}

@media (max-width: 767px){
  .certificate-table-header, .certificate-table-item {
    grid-template-columns: 1fr 1fr;
    gap: 10px
  }
  .certificate-table-image-wrap, .certificate-table-mobile-hide {
    display: none;
  }
}
.input-container {
    position: relative;
    width: 100%;
  }
  
  .input {
    font-size: 14px;
    font-family: "Manrope", sans-serif;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    width: 100%;
    position: relative;
  }
  
  .input::placeholder {
    color: #bdc1ca;
  }
  
  .toggle-password-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    width: 16px;
    height: 16px;
  }
  
  .input--default {
    color: black;
    background-color: #f3f4f6;
  }
  
  .input--dark {
    background-color: #171a1f;
    color: #dee1e6;
  }
  
  .input--dark::placeholder {
    color: #5c6475;
  }
  
  .input--underline {
    background-color: transparent;
    color: #bdc1ca;
    border: none;
    border-bottom: 1px solid #9095a1;
    border-radius: 0;
  }
  
  .feather {
    vertical-align: middle;
  }
  
  .input-error-message {
    color: tomato;
    font-size: 13px;
    margin-top: 4px;
  }
  
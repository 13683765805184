.tabs {
  border-bottom: 1px solid #f3f4f6;
  display: flex;
}

.tabs button.active {
  color: #15abff;
  font-weight: bold;
  border-radius: 0;
  border-bottom: 4px solid #15abff;
}

.mypage-course-list {
  max-width: 1080px;
  width: 100%;
}

.mypage-course-header {
  font-size: 14px;
  font-weight: 600;
  display: grid;
  grid-template-columns: 0.5fr 3fr 4fr 1.5fr 1.5fr 1.5fr;
  border-bottom: 0.5px solid #dee1e6;
  padding: 20px 22px;
}

.mypage-course-loading {
  padding: 20px;
}

.mypage-course-index-center-align {
  text-align: start;
  padding: 0 10px;
}

.mypage-course-index-start-align {
  text-align: start;
}

.mypage-course-info {
  display: grid;
  align-items: center;
  grid-template-columns: 0.5fr 3fr 4fr 1.5fr 1.5fr 1.5fr;
}

.mypage-course-item {
  font-family: "Manrope", Helvetica;
  font-size: 14px;
  font-weight: 400;
  color: #dee1e6;
  height: 86px;
  padding: 16px 22px;
}
.mypage-course-image-wrap {
  width: 80px;
  min-width: 80px;
  height: 54px;
  border-radius: 4px;
  overflow: hidden;
}

.mypage-course-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mypage-course-title {
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 0 16px;
}
.mypage-course-progress-wrap {
  display: flex;
  gap: 17px;
  align-items: center;
}

.mypage-course-progress{
  -webkit-appearance: none;
  appearance: none;
}

.mypage-course-progress::-webkit-progress-bar {
  max-width: 200px;
  width: 100%;
  height: 8px;
  background-color: #dee1e6;
  border-radius: 6px;
}

progress {
  max-width: 200px;
  width: 100%;
  height: 8px;
  border-radius: 6px;
  background-color: #dee1e6;
}

.mypage-course-progress::-webkit-progress-value {
  background-color: #BD9A31;
  border-radius: 6px;
}

.mypage-course-state {
  width: fit-content;
  height: 28px;
  background-color: #1e2128;
  border-radius: 14px;
  padding: 4px 9px;
  font-family: "Manrope", Helvetica;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.mypage-course-state.watching {
  color: #379ae6;
}

.mypage-course-state.surveying {
  color: #98690c;
}

.mypage-course-state.finished {
  color: #37750c;
}

@media (max-width: 1023px) {
  .mypage-course-header, .mypage-course-info {
    grid-template-columns: .5fr 4fr 4fr;
  }

  .mypage-course-image-wrap,
  .mypage-course-state,
  .mypage-course-index-start-align,
  .payment-date,
  .last-accessed {
    display: none;
  }
}
@media (max-width: 767px){
  .mypage-course-header, .mypage-course-info {
    grid-template-columns: .5fr 3fr 1fr;
  }
  .mypage-course-progress-wrap progress {
    display: none;
  }
  .sidebar-link-list {
    gap: 10px
  }
}

@media (max-width: 600px) {
  .sidebar-link-item svg {
    display: none;
  }
}

@media (max-width: 500px) {
  .sidebar-link {
    padding-left: 5px;
  }
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 35px;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 90%;
  color: black;
}

.modal-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
}

.modal-content p {
  margin-bottom: 30px;
  word-break: keep-all;
  line-height: 24px;
}

.modal-buttons-group {
  display: flex;
  justify-content: space-around;
}

.close-button {
  align-self: flex-end;
}
.setting-title-group {
  margin-top: 24px;
  padding-bottom: 50px;
  border-bottom: 1px solid #f3f4f6;
  display: flex;
  align-items: baseline;
  gap: 20px;
}

.setting-title {
  font-weight: bold;
  font-size: 32px;
}

.name-edit,
.email-edit,
.phone-edit,
.password-edit {
  max-width: 762px;
  min-width: 300px;
  background-color: #1e2128;
  margin-top: 43px;
  padding: 32px;
  display: flex;
  flex-direction: column;
}

.sign-out {
  max-width: 762px;
  min-width: 300px;
  background-color: #1e2128;
  margin-top: 43px;
  padding: 32px;
  display: flex;
  justify-content: space-between;
}

.name-edit > label,
.email-edit > label,
.phone-edit > label,
.password-edit > label,
.sign-out-label {
  display: block;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 4px;
}

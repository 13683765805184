.header {
  background-color: #1e2128;
  box-shadow: 0px 4px 9px #1e21281c, 0px 0px 2px #1e21281f;
  height: 92px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 64px;
  position: sticky;
  top: 0;
  z-index: 900;
}

.header .header-menu {
  width: 387px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .header-signin {
  display: flex;
  width: 64%;
  justify-content: space-between;
}

.menu-toggle-button {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.menu-overlay {
  display: none;
}

.header-dropdown-menu-mobile {
  display: none;
}

@media screen and (max-width: 1300px) {
  .header {
    padding: 0 5%;
  }

  .header .header-signin {
    display: none;
    position: fixed;
    top: 0;
    right: -250px;
    width: 250px;
    height: 100%;
    background-color: #1e2128;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    transition: right 0.3s ease;
    z-index: 1000;
  }

  .header .header-menu {
    display: none;
    position: fixed;
    top: 150px;
    right: -250px;
    width: 250px;
    height: fit-content;
    background-color: #1e2128;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    transition: right 0.3s ease;
    z-index: 1000;
  }

  .header .header-buttons {
    display: none;
    position: fixed;
    top: 0;
    right: -250px;
    width: 250px;
    height: fit-content;
    background-color: #1e2128;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    transition: right 0.3s ease;
    z-index: 1000;
  }

  .header .header-menu.open,
  .header .header-signin.open,
  .header .header-buttons {
    right: 0;
    display: flex;
    align-items: center;
  }

  .menu-toggle-button {
    display: block;
  }

  .menu-overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .header-dropdown-menu-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .header-dropdown-menu {
    display: none !important;
  }

  .header-user-name:hover {
    text-decoration-line: none !important;
  }

  .header-dropdown-menu-mobile .header-mypage-link,
  .header-dropdown-menu-mobile .header-logout-button {
    color: white;
    margin-top: 10px;
    background-color: transparent;
    border: none;
  }
}

.header-dropdown-menu {
  position: absolute;
  top: 75px;
  right: 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
}

.header-user-section {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #dee1e6;
  gap: 20px;
}

.header-dropdown-menu:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: white;
  border-top: 0;
  margin-left: -5px;
  margin-top: -10px;
}

.header-logout-button {
  background-color: transparent;
  border: 0;
  font-size: 16px;
}

.header-logout-button:hover {
  color: crimson;
  text-decoration-line: underline;
}

.header-mypage-link:hover {
  text-decoration-line: underline;
}

.header-link:hover {
  color: #bd9a31;
}

.header-user-name:hover {
  text-decoration-line: underline;
}

.header-buttons {
  display: flex;
}

@media (max-width: 375px) {
  .main-logo-link .link-text {
    display: none;
  }
}
.courses-background {
  width: 100vw;
  min-height: 1055px;
  background-color: #171a1f;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  padding-bottom: 30px;
}

.courses-section {
  max-width: 1180px;
  width: 100%;
  min-height: 1055px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 120px;
}

.courses-section-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.courses-section-header-h4 {
  font-family: "Manrope", Helvetica;
  color: #bd9a31;
  font-weight: bold;
  font-size: 20px;
}

.courses-section-header-h3 {
  font-family: "Lexend", Helvetica;
  color: #dee1e6;
  font-weight: bold;
  font-size: 40px;
}

.courses-section-pagenation {
  margin-top: 23px;
  display: flex;
  justify-content: space-between;
  gap: 14px;
}

.courses-section-title-wrap {
  display: flex;
  gap: 8px;
  align-items: end;
}

.courses-section-title {
  color: #dee1e6;
  font-size: 20px;
  font-weight: 700;
  align-self: end;
}

.courses-section-icon-button-wrap {
  display: flex;
  justify-content: end;
  gap: 14px;
}

.courses-section-pagenation .icon-button {
  cursor: pointer;
  width: 52px;
  height: 52px;
}

.course-list {
  display: flex;
  flex-flow: row wrap;
  gap: 40px 26px;
  margin-top: 28px;
}

.skeleton-card {
  display: flex;
  flex-direction: column;
  gap: 11px;
  max-width: 376px;
  width: 100%;
}

.image-skeleton {
  max-width: 376px;
  width: 100%;
  height: 227px;
  overflow: hidden;
  background: linear-gradient(90deg, #1e2128 25%, #2c303a 50%, #1e2128 75%);
  background-size: 200% 100%;
  animation: loading 2s infinite ease-in-out;
  border-radius: 8px;
}

.title-skeleton, .price-skeleton, .tag-skeleton {
  width: 60%;
  height: 24px;
  background: linear-gradient(90deg, #1e2128 25%, #2c303a 50%, #1e2128 75%);
  background-size: 200% 100%;
  animation: loading 2s infinite ease-in-out;
  border-radius: 8px;
}

.price-skeleton {
  width: 40%;
  height: 18px;
}

.tag-skeleton {
  width: 40px;
  height: 20px;
  border-radius: 14px;
}

@keyframes loading {
  0% {
      background-position: 200% 0;
  }
  100% {
      background-position: -200% 0;
  }
}
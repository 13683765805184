.find-password-box {
    max-width: 531px;
    width: 90%;
    min-height: 400px;
    background-color: white;
    border-radius: 6px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Lexend", Helvetica;
    justify-content: space-around;
}

.find-password-content{
    width: 100%;
}
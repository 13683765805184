.users-pagination-container {
  display: flex;
  flex-direction: column;
  margin-top: 43px;
  width: 1114px;
  background-color: #1e2128;
  padding: 46px 20px 20px;
}

.users-pagination-sort-wrap {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.users-pagination-input-wrap {
  width: 366px;
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
}

.users-pagination-search-input-wrap {
  position: relative;
}

.users-pagination-search-input {
  width: 366px;
  height: 35px;
  background-color: #171a1f;
  border-radius: 6px;
  color: #bdc1ca;
  border: none;
  font-size: 11pt;
  float: left;
  padding-left: 36px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 6px;
}

.users-list {
  width: 100%;
  background-color: #171a1f;
}

.users-list-header {
  display: grid;
  grid-template-columns: 0.5fr 1fr 2fr 1.5fr 1fr 1fr;
  padding: 20px 16px 16px;
  font-family: "Lexend", Helvetica;
  font-size: 14px;
  font-weight: 700;
  color: #dee1e6;
}

.users-item {
  font-family: '"Manrope", Helvetica';
  height: 56px;
  font-size: 14px;
  font-weight: 400;
  display: grid;
  align-items: center;
  grid-template-columns: 0.5fr 1fr 2fr 1.5fr 1fr 1fr;
  color: #dee1e6;
  padding: 20px 16px 16px;
}

.users-pagination-footer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  padding: 0 16px;
}

.users-pagination-buttons {
  display: flex;
  width: fit-content;
  align-items: center;
}

.users-pagination-button {
  background-color: transparent;
  border: none;
  width: 36px;
  height: 36px;
}

.users-pagination-button:hover {
  cursor: pointer;
}

.users-pagination-button.disabled:hover {
  cursor: not-allowed;
}

.ellipsis {
  margin: 0 8px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.users-pagination-page-button {
  background-color: transparent;
  color: #dee1e6;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 0;
}

.users-pagination-page-button:hover {
  background-color: #bd9a31;
  color: #1e2128;
  cursor: pointer;
}

.users-pagination-button-wrap {
  display: flex;
  gap: 4px;
}

.users-pagination-page-button.active {
  background-color: #bd9a31;
  color: #1e2128;
}

.users-pagination-count {
  color: #dee1e6;
}

.ad-popup {
  position: fixed;
  top: 92px;
  width: 400px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 899;
}

.ad-popup img {
  width: 100%;
  height: auto;
  display: block;
}

.ad-footer {
  background-color: #f5f5f5;
  padding: 6px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #666;
}

.ad-footer button {
  padding: 4px 12px;
  height: 24px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  line-height: 16px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

@media screen and (min-width: 1401px) {
  .ad-popup {
    width: 550px;
  }
  
  .ad-popup.position-2 {
    left: 590px; /* 550px + 20px margin + 20px gap */
  }
}

@media screen and (min-width: 801px) and (max-width: 1400px) {
  .ad-popup {
    width: 450px;
  }
  
  .ad-popup.position-2 {
    left: 340px; /* 300px + 20px margin + 20px gap */
  }
}

@media screen and (min-width: 601px) and (max-width: 800px) {
  .ad-popup {
    width: 80%;
    max-width: 450px;
  }
  
  .ad-popup.position-2 {
    top: 312px;
  }
}

@media screen and (min-width: 401px) and (max-width: 600px) {
  .ad-popup {
    width: 80%;
    max-width: 400px;
  }
  
  .ad-popup.position-2 {
    top: 292px;
  }
  
  .checkbox-label {
    font-size: 12px;
  }
  
  .ad-footer button {
    padding: 4px 8px;
    font-size: 12px;
  }
}

@media screen and (max-width: 400px) {
  .ad-popup {
    width: 80%;
  }
  
  .ad-popup.position-2 {
    top: 272px;
  }
  
  .checkbox-label {
    font-size: 8px;
  }
  
  .ad-footer button {
    padding: 4px 8px;
    font-size: 8px;
  }
}
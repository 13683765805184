.QnA-background {
  width: 100vw;
  min-height: calc(100vh - 286px);
  background-color: #171a1f;
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 0 30px;
  padding-bottom: 30px;
}

.QnA-section {
  max-width: 1150px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 120px;
}

.QnA-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.QnA-title {
  font-family: "Lexend", Helvetica;
  font-size: 32px;
  font-weight: 400;
  color: #dee1e6;
}

.QnA-search-input-wrap {
  max-width: 303px;
  width: 100%;
  position: relative;
}

.search-icon {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.QnA-search-input {
  max-width: 303px;
  width: 100%;
  height: 35px;
  background-color: #0f1114;
  border-radius: 6px;
  color: #bdc1ca;
  border: none;
  font-size: 11pt;
  padding-left: 36px;
}

@media screen and (max-width: 600px){
  .QnA-header {
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 20px;
    margin-left: 10px;
    width: 100%;
  }
}

.button {
    font-family: "Lexend", "Helvetica", sans-serif;
    font-size: 16px;
    font-weight: medium;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;
    outline: none;
    padding: 0;
    transition: background-color 0.3s, color 0.3s; /* 텍스트 색상에 대한 트랜지션을 추가 */
  }
  
  .button--2xs {
    width: 32px;
    height: 32px;
  }
  
  .button--xs {
    width: 105px;
    height: 36px;
  }
  
  .button--sm {
    width: 125px;
    height: 36px;
  }
  
  .button--md {
    width: 165px;
    height: 44px;
  }
  
  .button--lg {
    width: 200px;
    height: 56px;
  }
  
  .button--full {
    width: 100%;
    height: 56px;
  }
  
  .button--default {
    background-color: #bd9a31;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .button--primary {
    background-color: #0083ff;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .button--secondary {
    border: 1px solid #dee1e6;
    background-color: white;
    color: #9095a1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .button--danger {
    background-color: #f9623e;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .button--auth {
    background-color: #00c73c;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .button-icon {
    margin-right: 8px;
    vertical-align: middle;
  }
  
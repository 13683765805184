.service-form-wrap {
  max-width: 813px;
  font-family: '"Manrope", Helvetica';
}

.service-form-buttons-wrap {
  display: flex;
  gap: 26px;
  justify-content: end;
  padding-right: 26px;
  margin-bottom: 19px;
}

.save-temporarily-button {
  background-color: transparent;
  border: 1px solid #bd9a31;
  color: #bd9a31;
  font-size: 16px;
}

.save-temporarily-button,
.create-service-button {
  padding: 9px 39px;
  width: fit-content;
  height: fit-content;
}

.service-form-accordian {
  margin-top: 20px;
}

.service-form-accordian-header {
  width: 100%;
  background-color: #1e2128;
  border: 0;
  color: #dee1e6;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-radius: 6px;
}

.service-form-accordian-header:hover {
  cursor: pointer;
}

.service-form-accordian-content {
  background-color: #1e2128;
  color: #dee1e6;
  padding: 24px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.service-form-input-group {
  margin-bottom: 24px;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
}

.service-form-section-input-group {
  display: flex;
  gap: 30px;
  align-items: center;
}

.section-delete-button {
  color: #dee1e6;
  background-color: #b22222;
  border: 0;
  border-radius: 4px;
  height: fit-content;
  width: 80px;
  padding: 10px;
}

.service-form-input-group-flexbox {
  display: flex;
  width: 100%;
  gap: 20px;
}

.service-form-label {
  font-size: 14px;
  font-weight: 700;
}

.service-form-input {
  background-color: #171a1f;
  border-radius: 6px;
  color: #dee1e6;
  height: 35px;
  border: 0;
  padding-left: 9px;
  font-size: 14px;
}

.service-form-input::placeholder {
  color: #565d6d;
}

.service-form-course-summary, .service-form-course-description {
  background-color: #171a1f;
  border-radius: 6px;
  color: #dee1e6;
  height: 100px;
  border: 0;
  padding: 7px 12px;
}

.service-form-course-description {
  height: 177px;
}

.service-form-course-summary::placeholder, .service-form-course-description::placeholder {
  color: #565d6d;
}

.service-form-image-preview {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

input[type="file"]::file-selector-button {
  width: 100px;
  height: 35px;
  background-color: #dee1e6;
  border: 0;
  border-radius: 6px;
  color: #171a1f;
  font-size: 14px;
  margin-right: 20px;
  margin-bottom: 10px;
}

input[type="file"]::file-selector-button:hover {
  cursor: pointer;
}

.service-form-image-clear-button {
  width: 300px;
  height: 35px;
  background-color: transparent;
  border: 1px solid #dee1e6;
  border-radius: 6px;
  color: #dee1e6;
  font-size: 14px;
}

.service-form-image-clear-button:hover {
  cursor: pointer;
}

.service-form-add-section-button-wrap {
  display: flex;
  width: 100%;
  justify-content: end;
  gap: 10px;
}

.service-form-upload-button, .service-lecture-video-edit-button {
  background-color: #bd9a31;
  color: white;
  font-size: 14px;
  padding: 6px 18px;
  border-radius: 4px;
  border: 0;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.service-form-add-section-button {
  background-color: #bd9a31;
  font-size: 14px;
  color: #dee1e6;
  padding: 6px 18px;
  border-radius: 4px;
  border: 0;
  display: flex;
  align-items: center;
  gap: 6px;
}

.service-form-add-section-button:hover {
  cursor: pointer;
}

.service-form-course-section {
  margin-top: 40px;
}

.service-form-course-section-header {
  background-color: #171a1f;
  display: grid;
  grid-template-columns: 0.5fr 2fr 2fr 0.5fr;
  font-family: "Lexend", Helvetica;
  font-weight: 700;
}

.service-form-course-section-content {
  display: grid;
  grid-template-columns: 0.5fr 2fr 2fr 0.5fr;
  height: 77px;
  align-items: center;
  border: 1px solid #171a1f;
}

.service-form-course-section-content svg {
  width: 100%;
  text-align: center;
}

.service-form-course-section-content svg:hover {
  cursor: pointer;
}

.service-form-course-section-content input {
  padding: 0 16px;
  align-items: center;
  height: 35px;
}

.service-form-delete-lecture-button {
  height: 80%;
  background-color: transparent;
  border: 0;
}

.service-form-delete-lecture-button:hover {
  cursor: pointer;
}

.service-form-course-section-header div {
  padding: 12px 16px;
}

.lectures-video-url-wrap {
  display: flex;
  gap: 20px;
  align-items: center;
}

.lectures-video-url-text{
  max-width: 200px;
  overflow: hidden;
  padding-left: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.service-form-add-lecture-button {
  margin-top: 24px;
  margin-left: 12px;
  color: #f3c63f;
  font-size: 14px;
  font-weight: 700;
  background-color: #171a1f;
  border-radius: 4px;
  border: 0;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 7px 16px;
}

.service-form-add-lecture-button:hover {
  cursor: pointer;
}

.loader {
  border: 3px solid #565d6d;
  border-top: 3px solid #dee1e6;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.service-form-buttons-wrap {
  display: flex;
  align-items: center;
  gap: 20px;
}

.upload-progress-bar {
  flex-grow: 1;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.upload-progress-fill {
  height: 100%;
  background-color: #bd9a31;
  transition: width 0.3s ease;
}

.upload-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(33, 33, 33);
  font-weight: bold;
}
.p-policy-main {
    max-width: 782px;
    width: 100%;
    margin: 0 auto;
    padding: 60px 16px;
    box-sizing: border-box;
    color: #2f3438;
  }
  
  .p-policy-title {
    font-size: 24px;
    font-weight: bold;
    line-height: 40px;
    margin-bottom: 18px;
  }
  
  .p-policy-link-container {
    min-height: 114px;
    border: 1px solid rgb(234, 235, 239);
    border-radius: 4px;
    position: relative;
    margin-bottom: 40px;
  }
  
  .p-policy-link-container__inner {
    font-size: 13px;
    font-weight: normal;
    color: rgb(130, 140, 148);
  }
  
  .p-policy-link-container__inner > ol {
    margin: 24px;
    column-count: 3;
    column-gap: 12px;
  }
  
  @media (max-width: 767px) {
    .p-policy-link-container__inner > ol {
      column-count: 1;
    }
  }
  
  .p-policy-link-container__inner > ol > li > a {
    font-weight: normal;
    color: rgb(130, 140, 148);
    opacity: 1;
  }
  
  .p-policy-link-container__inner > ol > li > a:hover {
    opacity: 0.6;
  }
  
  .p-policy-link-container__inner > ol li {
    padding-bottom: 6px;
  }
  
  .p-policy-term-container {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
  }
  
  .p-policy-caution {
    font-size: 16px;
    font-weight: 700;
    color: #009fce;
  }

  .p-policy-header {
    margin: -40px 0px 10px;
    padding-top: 100px;
  }

  .p-policy-term-container h3 {
    margin: -40px 0px 10px;
    padding-top: 100px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 900;
  }
  
  .p-policy-term-container h4 {
    margin: -40px 0px 10px;
    padding-top: 100px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 900;
  }
  
  .p-policy-term-container ol {
    padding-left: 26px;
  }
  
  .p-policy-term-container > ol > li {
    margin: 10px 0px;
  }
  
  .p-policy-date-container__outer {
    width: 125px;
    height: 40px;
    margin-bottom: 18px;
  }
  
  .p-policy-date-container {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .p-policy-date-container-select {
    font-size: 15px;
    border-color: rgb(234, 235, 239);
    cursor: pointer;
    padding: 8px 15px 9px 12px;
    border-radius: 4px;
  }
  
  .p-policy-date-container-select option {
    -webkit-appearance: none;
  }
  
  .p-policy-header {
    width: 100%;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 50px;
  }

  .p-policy-term-description {
    margin-top: 20px;
  }
.signin-background {
    width: 100vw;
    min-height: 700px;
    height: calc(100vh - 286px);
    background-color: #171a1f;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .signin-box {
    width: 531px;
    height: 520px;
    background-color: white;
    border-radius: 6px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Lexend", Helvetica;
    justify-content: space-around;
  }
  
  .signin-title {
    font-size: 32px;
    font-weight: bold;
  }
  
  .signin-form {
    width: 100%;
    font-family: "Manrope", Helvetica;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .signin-input-label {
    font-size: 14px;
    font-weight: bold;
  }
  
  .signin-input {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  
  .signin-check-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .signin-check {
    display: flex;
    gap: 6px;
    font-size: 14px;
    font-weight: 400;
  }
  
  .signin-signup {
    margin-top: 16px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    gap: 5px;
  }
  
  .hr {
    margin-top: 35px;
    width: 100%;
    display: flex;
    gap: 12px;
    justify-content: space-between;
    margin-bottom: 28px;
  }
  
  .OR {
    color: #565d6d;
    font-family: "Lexend", Helvetica;
    font-size: 14px;
    font-weight: 400;
  }
  
  .color-white {
    color: #dee1e6;
  }
  
  #naverIdLogin {
    display: none;
  }
  
  
  @media (max-width: 600px) {
    .signin-box{
      width: 90%;
    }
  
    .signin-title {
      font-size: 24px;
    }
  
    .line-2, .line-3 {
      width: 40%;
    }
  
  }

  .naver-signin-wrap{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }


  .naver-loader {
    border: 3px solid #565d6d;
    border-top: 3px solid #dee1e6;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 0.8s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }